import { useLayoutEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import { useTranslation } from "react-i18next";

import Layout from "../components/layout";
import FigureList, {
  articlesFigureListAdapter,
} from "../components/Molecules/FigureList";
import Newsletter, {
  adapter as newsletterAdapter,
} from "../components/Molecules/Newsletter";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";

import { withTrans } from "../i18n/withTrans";
import { colors } from "../../styles/theme";
import { adapter as seoAdapter } from "../components/seo";
import { SearchCategory } from "../components/Molecules/Search";
import { configAdapter, adConfigAdapter } from "../utils/configAdapter";
import HeroText, {
  HeroTextCategoryAdapter,
} from "../components/Molecules/HeroText";

const searchIndices = [
  {
    name: `${process.env.GATSBY_WEBSITE}_INDEX`,
  },
];

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,

  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const Categorypage = ({ data, location, pageContext }) => {
  const { t } = useTranslation();

  const {
    uid,
    type,
    data: {
      smartadserver_page_name,
      smartadserver_page_id,
      smartadserver_disable_masthead,
      category_grid_fixedPushEnable,
    },
  } = data.prismicCategoryTroiscouleurs || {};

  const prismicPageContent = {
    uid,
    ...data.prismicCategoryTroiscouleurs?.data,
  };
  const prismicLastArticlesContent = data.allPrismicArticle?.edges;
  const prismicLastArticlesPushContent = prismicPageContent.articles_push;

  const prismicFeaturedArticle = prismicPageContent.featured_article;

  const prismicSiteConfiguration =
    data.allPrismicSiteConfiguration?.edges?.find(
      ({ node }) => node.data.site === pageContext.website
    ).node.data;

  const siteConfig = useMemo(
    () =>
      prismicSiteConfiguration &&
      configAdapter(prismicSiteConfiguration?.config),
    [prismicSiteConfiguration]
  );

  const adsConfig = useMemo(
    () =>
      prismicSiteConfiguration &&
      adConfigAdapter(
        {
          ...prismicSiteConfiguration,
          smartadserver_page_name,
          smartadserver_page_id,
          smartadserver_disable_masthead,
          category_grid_fixedPushEnable,
        },
        type
      ),
    [
      prismicSiteConfiguration,
      smartadserver_disable_masthead,
      smartadserver_page_id,
      smartadserver_page_name,
      category_grid_fixedPushEnable,
      type,
    ]
  );

  useLayoutEffect(() => {
    window.sas.cmd.push(function () {
      window.sas.call("onecall", {
        siteId: adsConfig.smartadserver_site_id,
        pageId: adsConfig.smartadserver_page_id,
        formats: [{ id: 96773 }],
      });
    });
  }, [adsConfig]);

  if (!prismicPageContent) return null;

  const searchUniverses = prismicPageContent.universes.map(
    ({ universe }) => universe?.document?.data?.name
  );
  const filterUniverses = searchUniverses
    ?.map(filter => filter && `universes.name:"${filter}" `)
    .join(" OR ");

  const searchThemes = prismicPageContent.themes.map(
    ({ theme }) => theme?.document?.data?.name
  );
  const filterTheme = searchThemes
    ?.map(filter => filter && `themes.name:"${filter}" `)
    .join(" OR ");

  const filterFormat =
    prismicPageContent.format && `format:"${prismicPageContent.format}"`;

  const filters = [filterUniverses, filterTheme, filterFormat]
    .filter(filters => filters)
    .join(" OR ");

  return (
    <Layout
      seoData={seoAdapter(data.prismicCategoryTroiscouleurs)}
      location={location}
      ads={adsConfig}
    >
      <HeroText {...HeroTextCategoryAdapter(prismicPageContent)} />
      <InstantSearch
        searchClient={searchClient}
        indexName={searchIndices[0].name}
      >
        <SearchCategory
          indices={searchIndices}
          config={{ ...siteConfig, category_grid_fixedPushEnable }}
          filters={filters}
          featuredArticle={prismicFeaturedArticle}
          ads={adsConfig}
        />
      </InstantSearch>
      {prismicLastArticlesContent && (
        <FigureList
          {...articlesFigureListAdapter({
            lastArticles: prismicLastArticlesContent,
            articlesPush: prismicLastArticlesPushContent,
            maxitems: 3,
          })}
          title={t("tc_articlepush")}
          columns={3}
          bgColor={colors.black}
          color={colors.white}
        />
      )}
      <Newsletter
        {...newsletterAdapter({ template: "troiscouleurs" }, { t })}
      />
    </Layout>
  );
};

export const query = graphql`
  query categoryTcQuery($uid: String!) {
    ...i18n
    prismicCategoryTroiscouleurs(uid: { eq: $uid }) {
      uid
      url
      type
      data {
        featured_article {
          document {
            ...Article
          }
        }
        smartadserver_page_id
        smartadserver_page_name
        smartadserver_disable_masthead
        category_grid_fixedPushEnable: category_grid_fixedpushenable
        meta_title
        meta_description
        meta_image {
          ...Image
        }
        format
        themes {
          theme {
            document {
              ...Theme
            }
          }
        }
        universes {
          universe {
            document {
              ...Universe
            }
          }
        }
        title
        subtitle
        hero_image {
          ...Image
        }
      }
    }
    ...InternalArticles
    ...Configuration
  }
`;

Categorypage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

Categorypage.defaultProps = {
  data: {},
};

export default withPreview(withTrans(Categorypage));
