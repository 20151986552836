import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import { mediaquery, scaleSpacing } from "../../../../styles/theme";
import RichText from "../../Atoms/RichText";

import {
  NotoEditoLarge,
  OswaldH1,
  OswaldH2,
  OswaldOverline1,
  OswaldOverline2,
} from "../../Atoms/Typography";

const Container = styled.section`
  position: relative;
  padding: calc(${scaleSpacing(10)} + var(--header-height)) 0 ${scaleSpacing(8)};

  ${mediaquery.md(css`
    padding: calc(${scaleSpacing(14)} + var(--header-height)) 0
      ${scaleSpacing(10)};
  `)};
`;

const ContentContainer = styled.div`
  position: relative;
  padding: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 2 * var(--external-gutter));
  height: 100%;
  color: ${({ color }) => color};
`;

const Headline = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: ${scaleSpacing(4)};
  ${OswaldOverline2}

  ${mediaquery.md(css`
    ${OswaldOverline1}
  `)};
`;

const Title = styled.div`
  color: inherit;
  text-align: center;
  ${OswaldH2}
  width: 100%;

  ${mediaquery.md(css`
    ${OswaldH1}
  `)};
`;

const Description = styled.div`
  color: inherit;
  text-align: center;
  ${NotoEditoLarge}
  width: 100%;
  padding: 0 var(--gutter-size);
  margin-top: ${scaleSpacing(4)};

  ${mediaquery.md(css`
    padding: 0;
  `)};
`;

const Teaser = ({ headline, title, description }) => {
  const theme = useTheme();

  return (
    <Container>
      <ContentContainer color={theme?.foregroundColor}>
        {!!headline && <Headline>{headline}</Headline>}
        {!!title && <Title>{title}</Title>}
        {!!description && (
          <Description>
            {" "}
            <RichText data={description} />
          </Description>
        )}
      </ContentContainer>
    </Container>
  );
};

Teaser.propTypes = {
  headline: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Teaser;
