export const HeroTextCategoryAdapter = ({
  title,
  label,
  subtitle,
  description,
  image,
  hero_image,
}) => {
  return {
    description: subtitle || description?.raw,
    title: title || label,
    image: image || hero_image,
  };
};
